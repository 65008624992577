import React, { useState } from "react"
import Root from "../components/root"
import classes from "./terms-conditions.module.css"
import Logo from "../assets/web_resources/logo-white.png"
import Dropdown from "../components/Dropdown"
import Layout from "../components/layout"

const PrivacyPolicyPage = () => {
  const [isDropdownOpen, setisDropdownOpen] = useState(false)

  return (
    <Layout>
      <Root className={classes.root}>
        {!isDropdownOpen && (
          <div className={classes.logo} onClick={e => setisDropdownOpen(true)}>
            <img src={Logo} alt="" />
          </div>
        )}

        {isDropdownOpen && <Dropdown white2={true} />}

        <div
          className={classes.container}
          onClick={e => setisDropdownOpen(false)}
        >
          <div className={classes.heading}>
            <span className={classes.headSpan}>Mad</span> Jon's
          </div>
          <div className={classes.subHeading}>
            <span className={classes.headSpan}>PRIVACY</span>
            <span className={classes.headSpan}>POLICY</span>
          </div>
          <div className={classes.text}>
            <p>Effective date: April 01, 2021</p>
            <p>
              MAD JON PRODUCTIONS SRL (“us”, “we”, or “our”) operates the
              <span>https://themadjon.com/</span>
              website (the “Service”).
            </p>
            <p>
              This page informs you of our policies regarding the collection,
              use, and disclosure of personal data when you use our Service and
              the choices you have associated with that data.
            </p>
            <p>
              We use your data to provide and improve the Service. By using the
              Service, you agree to the collection and use of information in
              accordance with this policy. Unless otherwise defined in this
              Privacy Policy, terms used in this Privacy Policy have the same
              meanings as in our Terms and Conditions, accessible from{" "}
              <span>https://themadjon.com/</span>
            </p>
            <p>
              MAD JON PRODUCTIONS SRL (referred to as “MAD JON”.” THE MAD
              JON”,“US”, “WE”) takes your privacy seriously. We will not sell,
              lease, or exchange your personal data to, or otherwise share your
              personal data with, third parties in ways other than described in
              this Privacy Policy.
            </p>
            <p>
              MAD JON complies with the EU-U.S. Privacy Shield Framework as set
              forth by the U.S. Department of Commerce regarding the collection,
              use, and retention of personal information transferred from the
              European Union and the United Kingdom to the United States in
              reliance on Privacy Shield.
            </p>
            <p>
              By using The MAD JON Website/Site including services available at
              the <span>https://themadjon.com/</span> domains, you are using a
              service provided by MAD JON (the “Service”) and you agree to be
              bound by the Terms of Service and this Privacy Policy. Some
              capitalized terms used in this Privacy Policy and not otherwise
              defined may be defined in the Terms of Service. This Privacy
              Policy only addresses activities from our service.
            </p>
            <p>
              Definitions: <br /> - “Applicable Data Protection Laws” means all
              laws and regulations, including laws and regulations of California
              and the European Union and the European Economic Area and their
              member states, Switzerland and the United Kingdom, applicable to
              the Processing of Personal Data under the Principle Agreement;{" "}
              <br /> - “Affiliate” means any entity that owns or controls, is
              owned or controlled by, or is under common control or ownership
              with a party to this DPA, where control is defined as the direct
              or indirect ownership or control of more than 50% of the voting
              interests of the entity; <br /> - “Controller” means the entity
              that alone or jointly with others, determines the purposes and
              means of the Processing of Personal Data. For the purposes of this
              DPA only, the term Controller shall include Controller Affiliates;{" "}
              <br /> - “Customer” means the entity identified as such as a
              signatory to this DPA, and identified as “Customer” or “User” in
              the Principal Agreement. For the purposes of this DPA only, the
              term “Customer” shall include Customer Affiliates; <br /> -
              “Customer Personal Data” means any Personal Data Processed by MAD
              JON on behalf of a Customer pursuant to or in connection with the
              Principal Agreement;
              <br /> - “Data Subject” means the identified or identifiable
              natural person to whom Personal Data relates; <br /> - “Delete”
              means to remove or obliterate Personal Data such that it cannot be
              recovered or reconstructed; <br /> - “EU Data Protection Laws”
              means EU Directive 95/46/EC, as transposed into domestic
              legislation of each Member State and as amended, replaced or
              superseded from time to time, including by the GDPR and laws
              implementing or supplementing the GDPR; <br /> - “GDPR” means EU
              General Data Protection Regulation 2016/679;
              <br /> - “CCPA” means the California Consumer Privacy Act, Cal.
              Civ. Code 1798.100 et seq.,including any amendments and any
              implementing regulations; <br /> - “Personal Data” means any
              information relating to Data Subject which is subject to the
              Applicable Data Protection Laws and which Processor Processes on
              behalf of Controller other than Anonymous Data; <br /> -
              “Principal Agreement” means MAD JON’s Terms of Service Agreement,
              Order Form or any other written or electronic agreement for the
              purchase of services from MAD JON (identified as “Services” in the
              applicable agreement or order form), which Customer has signed up
              for and agreed to, including MAD JON’s Privacy Policy; <br /> -
              “Processor” means the entity, including its Affiliates which
              Processes Personal Data on behalf of the Controller; <br /> -
              “Services” means the services and other activities to be supplied
              to or carried out by or on behalf of MAD JON for Customer pursuant
              to the Principal Agreement; <br /> - “Standard Contractual
              Clauses” means the contractual clauses attached hereto as Exhibit
              1 pursuant to the European Commission’s decision (C(2010)593) of 5
              February 2010 on Standard Contractual Clauses for the transfer of
              personal data to processors established in 3third countries which
              do not ensure an adequate level of data protection (or any updated
              version thereof); <br /> - Sub-processor” means any Processor
              engaged by or on behalf of MAD JON or any MAD JON Affiliate to
              Process Personal Data on behalf of Customer pursuant to the
              Principal Agreement; <br /> - Anonymous Data” means Personal Data
              that has been processed in such a manner that it can no longer be
              attributed to an identified or identifiable natural person; <br />{" "}
              - The terms, "Commission", "Personal Data Breach", "Process",
              "Processing" and "Supervisory Authority" shall have the same
              meaning as in the GDPR. <br /> - “Business day” – MAD JON operates
              in the Romania time zone and delivers customer support Monday –
              Saturday from 9am – 5pm EET.
            </p>
            <p>
              What personal information do we collect from the people that visit
              our website? <br /> Personal Data <br /> While using our Service,
              we may ask you to provide us with certain personally identifiable
              information that can be used to contact or identify you (“Personal
              Data”). Personally identifiable information may include, but is
              not limited to:
            </p>
            <p>
              <div>
                <span className={classes.spanDash}>Email address</span>
              </div>
              <div>
                <span className={classes.spanDash}>
                  {" "}
                  First name and last name
                </span>
              </div>
              <div>
                <span className={classes.spanDash}>Phone number</span>
              </div>
              <div>
                <span className={classes.spanDash}>
                  Address, State, Province, ZIP/Postal code, City
                </span>
              </div>
              <div>
                <span className={classes.spanDash}>
                  {" "}
                  Cookies and Usage Data
                </span>
              </div>
            </p>
            <p>
              {" "}
              Usage Data <br /> We may also collect information how the Service
              is accessed and used (“Usage Data”). This Usage Data may include
              information such as your computer’s Internet Protocol address
              (e.g. IP address), browser type, browser version, the pages of our
              Service that you visit, the time and date of your visit, the time
              spent on those pages, unique device identifiers and other
              diagnostic data.
            </p>
            <p>
              {" "}
              Tracking & Cookies <br />
              Data We use cookies and similar tracking technologies to track the
              activity on our Service and hold certain information.
            </p>
            <p>
              {" "}
              Cookies are files with small amount of data which may include an
              anonymous unique identifier. Cookies are sent to your browser from
              a website and stored on your device. Tracking technologies also
              used are beacons, tags, and scripts to collect and track
              information and to improve and analyze our Service.
            </p>
            <p>
              {" "}
              You can instruct your browser to refuse all cookies or to indicate
              when a cookie is being sent. However, if you do not accept
              cookies, you may not be able to use some portions of our Service.
            </p>
            <p>Example of cookies we use:</p>
            <p>
              <div>
                <span className={classes.spanDash}>
                  Session Cookies. We use Session Cookies to operate our
                  Service.
                </span>
              </div>
              <div>
                <span className={classes.spanDash}>
                  Preference Cookies. We use Preference Cookies to remember your
                  preferences and various settings.
                </span>
              </div>
              <div>
                <span className={classes.spanDash}>
                  Security Cookies. We use Security Cookies for security
                  purposes.
                </span>
              </div>
            </p>
            <p>
              Use of Data <br /> MAD JON PRODUCTIONS SRL uses the collected data
              for various purposes:
            </p>
            <p>
              <div>
                <span className={classes.spanDash}>
                  To provide and maintain the Service.
                </span>
              </div>
              <div>
                <span className={classes.spanDash}>
                  To notify you about changes to our Service.
                </span>
              </div>
              <div>
                <span className={classes.spanDash}>
                  To allow you to participate in interactive features of our
                  Service when you choose to do so.
                </span>
              </div>{" "}
              <div>
                <span className={classes.spanDash}>
                  To provide customer care and support.
                </span>
              </div>{" "}
              <div>
                <span className={classes.spanDash}>
                  To provide analysis or valuable information so that we can
                  improve the Service.
                </span>
              </div>
              <div>
                <span className={classes.spanDash}>
                  To monitor the usage of the Service.
                </span>
              </div>{" "}
              <div>
                <span className={classes.spanDash}>
                  To detect, prevent and address technical issues.
                </span>
              </div>
            </p>
            <p>
              {" "}
              When ordering or registering on our site, as appropriate, you may
              be asked to enter your name, email address, mailing address, phone
              number, credit card information or other details to help you with
              your experience.
            </p>
            <p>
              {" "}
              When do we collect information? <br /> We collect information from
              you when you place an order, subscribe to a newsletter or enter
              information on our site.
            </p>
            <p>
              How do we use your information? <br /> We may use the information
              we collect from you when you register, make a purchase, sign up
              for our newsletter, respond to a survey or marketing
              communication, surf the website, or use certain other site
              features to allow us to better service you in responding to your
              customer service requests.
            </p>
            <p>
              Comments <br /> When you leave comments on the Website we collect
              the data shown in the comments form, and also the IP address and
              browser user agent string to help spam detection.
            </p>
            <p>
              {" "}
              These submissions are only kept for customer service purposes they
              may be used for marketing purposes but never shared with third
              parties.
            </p>
            <p>
              {" "}
              Payment processing <br /> We use Stripe Inc. as a gateway payment
              processor in order to process payments for our services and to
              store sensitive data regarding payments. For more information,
              please see : <span>Stripe terms & privacy policy </span>
            </p>
            <p>
              {" "}
              Stripe securely stores any sensitive data regarding payments and
              we do not have access to that data, any possible liability
              resulting and regarding this data is entirely the concern of the
              client and Stripe Inc. We do not take any responsibility
              whatsoever in managing such sensitive data.
            </p>
            <p>
              {" "}
              Website Content <br /> We use Contentful in order to manage our
              website content. For more information, please see :{" "}
              <span>Contentful terms & privacy policy </span>
            </p>
            <p>
              {" "}
              Google Analytics <br /> We use Google Analytics on our site for
              anonymous reporting of site usage. So, no personalized data is
              stored. If you would like to opt-out of Google Analytics
              monitoring your behavior on our website please use this link:{" "}
              <span>Google Analytics Opt-out</span> .
            </p>
            <p>
              {" "}
              Dropbox Data Transfer <br /> We accept Dropbox Data Transfer for
              highly secure data transfer from you to our editors. If you would
              like to know more about Dropbox:{" "}
              <span> Dropbox terms & privacy policy</span>
            </p>
            <p>
              {" "}
              Google Drive Data Transfer We accept Google Drive Data Transfer
              for highly secure data transfer from you to our editors. If you
              would like to know more about Google Drive:{" "}
              <span>Google terms & privacy policy</span>
            </p>
            <p>
              {" "}
              How Long We Retain Your Data <br /> When you submit a support
              ticket or a comment, its metadata is retained until (if) you tell
              us to remove it. We use this data so that we can recognize you and
              approve your comments automatically instead of holding them for
              moderation.
            </p>
            <p>
              {" "}
              If you register on our website, we also store the personal
              information you provide in your user profile. You can see, edit,
              or delete your personal information at any time (except changing
              your username). Website administrators can also see and edit that
              information.
            </p>
            <p>
              {" "}
              Embedded Content <br /> Pages on this site may include embedded
              content, like YouTube videos, for example. Embedded content from
              other websites behaves in the exact same way as if you visited the
              other website.
            </p>
            <p>
              {" "}
              These websites may collect data about you, use cookies, embed
              additional third-party tracking, and monitor your interaction with
              that embedded content, including tracking your interaction with
              the embedded content if you have an account and are logged-in to
              that website. Below you can find a list of the services we use:
            </p>
            <p>
              {" "}
              Vimeo <br /> We use YouTube videos embedded on our site. Vimeo has
              its own cookie and privacy policies over which we have no control.
              There is no installation of cookies from Vimeo and your IP is not
              sent to a Vimeo server until you consent to it. See their privacy
              policy here: <span>Vimeo Privacy Policy</span> .
            </p>
            <p>
              Youtube <br /> We use YouTube videos embedded on our site. YouTube
              has its own cookie and privacy policies over which we have no
              control. There is no installation of cookies from YouTube and your
              IP is not sent to a YouTube server until you consent to it. See
              their privacy policy here: <span>YouTube Privacy Policy</span>.
            </p>
            <p>
              Consent Choice <br /> We provide you with the choice to accept
              this or not, we prompt consent boxes for all embedded content, and
              no data is transferred before you consented to it.
            </p>
            <p>
              The checkboxes below show you all embeds you have consented to so
              far. You can opt-out any time by un-checking them and clicking the
              update button.
              <div>
                <span> YouTube </span>
              </div>
              <div>
                <span>Vimeo</span>
              </div>
            </p>
            <p>
              Consent and Information Collection and Use <br /> By using the
              service offered by MAD JON, you will be required to provide us
              with video footage as well as other types of content such as, but
              not limited to: graphics, photographs, audio or any other project
              related information.
            </p>
            <p>
              Your content, including the raw footage that you provide and any
              video montages that contain parts of your footage, will not be
              sold or rented to third-party affiliates, parent companies or
              their subsidiaries that are outside of MAD JON without your
              permission.
            </p>
            <p>
              When you join us as a user of our Services, we ask for personal
              information that will be used to activate your account, provide
              the Services to you, communicate with you about the status of your
              account, and for other purposes set out in this Privacy Policy.
              Your name, company name, address, telephone number, email address,
              credit card information and certain other information about you
              may be required by us to provide the Services or disclosed by you
              during your use of the Services. You will also be asked to create
              a private password, which will become part of your account
              information.
            </p>
            <p>
              By providing personal information to us and by retaining us to
              provide you with the Services, you voluntarily consent to the
              collection, use and disclosure of such personal information as
              specified in this Privacy Policy. Without limiting the foregoing,
              we may on occasion ask you to consent when we collect, use, or
              disclose your personal information in specific circumstances.
              Sometimes your consent will be implied through your conduct with
              us if the purpose of the collection, use or disclosure is obvious
              and you voluntarily provide the information.
            </p>
            <p>
              We may use your personal or account information for the following
              purposes:{" "}
              <div>
                <span className={classes.spanDash}>
                  To provide the Services to you and to improve the quality of
                  the Site and Services;
                </span>
              </div>{" "}
              <div>
                <span className={classes.spanDash}>
                  To provide information to you so that you may use the Site and
                  Services more effectively;
                </span>
              </div>
              <div>
                <span className={classes.spanDash}>To bill you;</span>
              </div>
              <div>
                <span className={classes.spanDash}>
                  To communicate with you for the purpose of informing you of
                  changes or additions to the Services, or of the availability
                  of any service we provide;
                </span>
              </div>
              <div>
                <span className={classes.spanDash}>
                  To assess service levels, monitor traffic patterns and gauge
                  popularity of different service options;
                </span>
              </div>
              <div>
                <span className={classes.spanDash}>
                  To carry out our marketing activities;
                </span>
              </div>
              <div>
                <span className={classes.spanDash}>
                  To enforce this Privacy Policy;
                </span>
              </div>
              <div>
                <span className={classes.spanDash}>
                  To respond to claims of any violation of our rights or those
                  of any third parties;
                </span>
              </div>
              <div>
                <span className={classes.spanDash}>
                  To respond to your requests for customer service;
                </span>
              </div>
              <div>
                <span className={classes.spanDash}>
                  To protect the rights, property or personal safety of you, us,
                  our users and the public; and as required or authorized by
                  law.
                </span>
              </div>
            </p>
            <p>
              We may occasionally communicate with you regarding our products,
              services, news and events. You have the option to not receive this
              information. We provide an opt-out function within all email
              communications of this nature, or will cease to communicate with
              you for this purpose if you contact us and tell us not to
              communicate this information to you. The only kind of these
              communications that you may not “opt-out” of are those required to
              communicate announcements related to the Services.
            </p>
            <p>
              Age of Consent <br /> We do not knowingly provide the Services to,
              and will not knowingly collect the personal information from
              anyone under the age of consent.
            </p>
            <p>
              Rights to Your Information <br /> You have the right to request,
              edit and delete your information at any time communicating your
              intention to us in a message sent to
              <span>office@themadjon.com</span>.
            </p>
            <p>
              California Residents rights In accordance with California Consumer
              Privacy Act (CCPA), we do not sell, rent, or share personal
              information with third parties as defined under the California
              Consumer Privacy Act of 2018 (California Civil Code Sec. 1798.100
              et seq.), nor do we sell, rent, or share personal information with
              third parties for their direct marketing purposes as defined under
              California Civil Code Sec. 1798.83. For more information please
              see here: <span>California CCPA Bill</span>
            </p>
            <p>
              EU Residents MAD JON will subject all EU residents personal
              information received via the Privacy Shield to the EU-U.S. Privacy
              Shield Principles. <br /> MAD JON generally offers EU residents
              the opportunity to choose (opt out) whether their personal data
              may be (i) disclosed to a third party or (ii) used for a purpose
              that is materially different from the purpose(s) for which it was
              originally collected or subsequently authorized by you.
            </p>
            <p>
              If you wish to opt out, you can contact us at
              <span> office@themadjon.com</span>. <br /> The above
              choice/opt-out doesn’t apply where the sharing of your personal
              data is with a third party who is acting as our agent (such as our
              service providers who perform services that help us to run our
              business). We will not provide your personal data to a third party
              under these circumstances unless we have a contract in place with
              that third party.
            </p>
            <p>
              GDPR Rights <br /> Your privacy is critically important to us.
              Going forward with the GDPR we aim to support the GDPR standard.
              MAD JON permits residents of the European Union to use its
              Service. Therefore, it is the intent of MAD JON to comply with the
              European General Data Protection Regulation. For more information
              please see here: <span>EU GDPR Information Portal</span> .
            </p>
            <p>
              Digital Millenium Copyright Act or DMCA <br /> In the event
              materials are made available to this Website by third parties not
              within our control (ie: stock footage, creative commons license),
              we are under no obligation to, and do not, scan such content for
              the inclusion of illegal or impermissible content. However, we
              respect the copyright interests of others. It is our policy not to
              permit materials known by us to infringe another party’s copyright
              to remain on the Site. If you believe any materials on the Site
              infringe a copyright, you should provide us with written notice
              that at a minimum contains:
            </p>
            <p>
              <div>
                <span className={classes.spanDash}>
                  A physical or electronic signature of a person authorized to
                  act on behalf of the owner of an exclusive right that is
                  allegedly infringed;
                </span>
              </div>
            </p>
            <p>
              <div>
                <span className={classes.spanDash}>
                  Identification of the copyrighted work claimed to have been
                  infringed, or, if multiple copyrighted works at a single
                  online site are covered by a single notification, a
                  representative list of such works at that site;
                </span>
              </div>
            </p>
            <p>
              <div>
                <span className={classes.spanDash}>
                  Identification of the material that is claimed to be
                  infringing or to be the subject of infringing activity and
                  that is to be removed or access to which is to be disabled,
                  and information reasonably sufficient to permit us to locate
                  the material;
                </span>
              </div>
            </p>
            <p>
              <div>
                <span className={classes.spanDash}>
                  Information reasonably sufficient to permit us to contact the
                  complaining party, such as an address, telephone number, and,
                  if available, an electronic mail address at which the
                  complaining party may be contacted;
                </span>
              </div>
            </p>
            <p>
              <div>
                <span className={classes.spanDash}>
                  A statement that the complaining party has a good-faith belief
                  that use of the material in the manner complained of is not
                  authorized by the copyright owner, its agent, or the law;
                </span>
              </div>
            </p>
            <p>
              <div>
                <span className={classes.spanDash}>
                  and A statement that the information in the notification is
                  accurate, and under penalty of perjury, that the complaining
                  party is authorized to act on behalf of the owner of an
                  exclusive right that is allegedly infringed.
                </span>
              </div>
            </p>
            <p>
              For more information please see here:{" "}
              <span> Digital Millenium Copyright Act</span> <br /> All DMCA
              notices should be sent to our designated agent as follows: <br />{" "}
              Alexandru Ioan <br />
              +40755141398 <br /> <span>office@themadjon.com</span>
            </p>
            <h1 className={classes.disclosure}>Disclosure</h1>
            <p>
              We will only share your information with third parties in
              accordance with your instructions or as necessary to provide you
              with a specific service or otherwise in accordance with applicable
              privacy legislation. As a general rule, we do not and will not
              sell, rent, share or disclose your personal information without
              first receiving your permission or as permitted in this Privacy
              Policy.
            </p>
            <p>
              Aggregated Data <br /> We may also use your personal information
              to generate Aggregated Data for internal use and for sharing with
              others on a selective basis. “Aggregated Data” means records which
              have been stripped of information potentially identifying
              customers, landing pages or end-users, and which have been
              manipulated or combined to provide generalized, anonymous
              information. Your identity and personal information will be kept
              anonymous in Aggregated Data.
            </p>
            <p>
              Links <br /> The Site may contain links to other sites and we are
              not responsible for the privacy practices or the content of such
              sites. We encourage you to read the privacy policy of linked
              sites. Their privacy policies and practices differ from our
              policies and practices. We have no control over and assume no
              responsibility for the content, privacy policies or practices of
              any third party sites or services.
            </p>
            <p>
              Cookies and Log Files <br /> We use cookies and log files to track
              user information. Cookies are small amounts of data that are
              transferred to your web browser by a web server and are stored on
              your computer’s hard drive. We use cookies to track which page
              variant a visitor has seen, to track if a visitor has clicked on a
              page variant, to monitor traffic patterns and to gauge popularity
              of service options. We will use this information to deliver
              relevant content and services to you. This also allows us to make
              sure that visitors see the website they expect to see if they
              return to the same web URL and it allows us to tell you how many
              people click on your website.
            </p>
            <p>
              In general, cookies are used to retain user preferences, store
              information for things like shopping carts, and provide anonymized
              tracking data to third party applications like Google Analytics.
              Cookies generally exist to make your browsing experience better.
              However, you may prefer to disable cookies on this site and on
              others. The most effective way to do this is to disable cookies in
              your browser. We suggest consulting the help section of your
              browser.
            </p>
            <p>
              Change of Ownership or Business Transition <br /> In the event of
              a change of ownership or other business transition, such as a
              merger, acquisition or sale of our assets, your information may be
              transferred in accordance with applicable privacy laws.
            </p>
            <p>
              Security <br /> We will strive to prevent unauthorized access to
              your personal information, however, no data transmission over the
              Internet, by wireless device or over the air is guaranteed to be
              100% secure. Your personal information is contained behind secured
              networks and is only accessible by a limited number of persons who
              have special access rights to such systems, and are required to
              keep the information confidential. In addition, all
              sensitive/credit information you supply is encrypted via Secure
              Socket Layer (SSL) technology. <br /> We implement a variety of
              security measures when a user places an order enters, submits, or
              accesses their information to maintain the safety of your personal
              information. All transactions are processed through a gateway
              provider and are not stored or processed on our servers.
            </p>
            <p>
              Please remember that you control what personal information you
              provide while using the Services. Ultimately, you are responsible
              for maintaining the secrecy of your identification, passwords
              and/or any personal information in your possession for the use of
              the Services. Always be careful and responsible regarding your
              personal information. We are not responsible for, and cannot
              control, the use by others of any information which you provide to
              them and you should use caution in selecting the personal
              information you provide to others through the Services. Similarly,
              we cannot assume any responsibility for the content of any
              personal information or other information which you receive from
              other users through the Services, and you release us from any and
              all liability in connection with the contents of any personal
              information or other information which you may receive using the
              Services. We cannot guarantee, or assume any responsibility for
              verifying, the accuracy of the personal information or other
              information provided by any third party. You release us from any
              and all liability in connection with the use of such personal
              information or other information of others.
            </p>
            <p>
              Amendments <br /> We may amend this Privacy Policy from time to
              time. When we amend this Privacy Policy, we will update this page
              accordingly and require you to accept the amendments in order to
              be permitted to continue using our services.
            </p>
            <p>
              Contact Us If you have any questions or suggestions regarding our
              Privacy Policy, please contact us at{" "}
              <span>office@themadjon.com</span>
            </p>
          </div>
        </div>
      </Root>
    </Layout>
  )
}

export default PrivacyPolicyPage
